// dev env
export const environment = {
    production: false,
    siteUrl: 'https://yourit-ebilet-portal-dev.azurewebsites.net',
    portalApi: '/api',
    portalApiBaseUrl: 'https://yourit-ebilet-dev.westeurope.cloudapp.azure.com',
    blogUrl: 'https://www.p.ebilet.club/now/',
    shoppingCartApi: 'https://yourit-ebilet-shop-shopingcart-dev.azurewebsites.net/api',
    titleEventApi: 'https://yourit-ebilet-shop-events-dev.azurewebsites.net/api',
    selfServiceApi: 'https://yourit-ebilet-shop-selfservice-dev.azurewebsites.net/api',
    captchaSiteKey: '6LfHWLIgAAAAADruxCzhc9Fi_C59JZrnSbFQgeKB',
    gtmId: 'GTM-WC8JX95',
    syneriseTrackerKey: '3464b329-14db-4387-8129-dc4cb9a13071',
    appInsightsInstrumentationKey: '15b75d60-3a94-4156-9abb-0bd943866340',
    shopUrl: 'https://as-shop-dev-01l.azurewebsites.net/',
    /** Używane przez transloco podczas ssr, wskazuje na folder assets */
    baseUrl: 'https://ebilet-portal-dev-frontend.azureedge.net',
    sitemapBaseUrl: 'https://ebilet-portal-dev.azureedge.net/portal',
    cacheBlobUrl: 'https://yitnewportaldev.blob.core.windows.net/portal/cache',
    mediaUrl: 'https://ebilet-portal-dev.azureedge.net',
    mediaStay22: 'https://ebilet-portal-dev.azureedge.net',
    cdnUrl: 'https://ebilet-portal-dev.azureedge.net',
    logXHR: true,
    logRender: false,
    tradedoublerPrefix: 'aatj',
    cookiebotTrackerId: '75c58c25-7405-4279-a705-ec4dc18c236b',
    localProd: false,
};
